import {useState} from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import {
    Alert,
    Button,
    Card,
    createTheme,
    TextField,
    ThemeProvider
} from '@mui/material';
import WheelComponent from "./Wheel";

const colors = {
    body: '#510087',
    bg: '#3d0066',
    bgLight: '#5c0099',
    primary: '#fdc500',
    primaryLight: '#ffd500'
}

const mysteryGiftTheme = createTheme({
    palette: {
        primary: {
            main: '#fdc500',
        },
    },
});

const credentials = {
    securityCode: '',
};

const lead = {
    email: '',
};

function App() {
    const segments = [
        'kubek Contigo',
        'zdrapka mapsy.me',
        'scyzoryk Victorinox',
        'zestaw map Comfort',
        'organizer Betlewski',
        'voucher Ryanair',
        'atlas gór Polski',
        'zestaw MoyaPucha',
        'bilet weekendowy PKP',
        'selfie stick Xiaomi',
        'voucher Decathlon',
        'apteczka podróżna'
    ]
    const segColors = [
        '#49007a',
        '#310052',
        '#49007a',
        '#56008f',
        '#6200a3',
        '#EC0B43',
        '#6200a3',
        '#56008f',
        '#49007a',
        '#310052',
        '#49007a',
        '#56008f',
    ];
    const onFinished = (winner) => {
        setTimeout(() => {
            setShowNextButton(true);
        }, 1000);
    };

    const onChanged = (prize) => {
        setSamplePrize(prize);
    };

    const onNext = (event) => {
        setDrawStage(3);
    };

    let intervalId;
    let countingVoucherValue = 0;

    const checkingVoucherValueStep = () => {
        countingVoucherValue += 10;
        setVoucherValue(countingVoucherValue);
        if (countingVoucherValue >= 720) {
            clearInterval(intervalId);
            setIsVoucherValueFinal(true);
        }
    };

    const checkVoucherValue = (event) => {
        setIsCheckingVoucherValue(true);
        intervalId = setInterval(checkingVoucherValueStep, 50);
    };

    const getVoucherDetails = async (event) => {
        setDrawStage(4);
        await getVoucherData();
        await sendVoucherViaSms();
    };

    const getInitialDrawStage = () => {
        if (window.location.href.includes('redeem')) {
            if (window.location.href.includes('redeem/X6CBY')) {
                return 1;
            }
            return 99;
        }
        return 0;
    };

    const [showInvalidCredentialsWarning, setShowInvalidCredentialsWarning] = useState(0);
    const [showLeadAdded, setShowLeadAdded] = useState(0);
    const [drawStage, setDrawStage] = useState(getInitialDrawStage());
    const [samplePrize, setSamplePrize] = useState('???');
    const [showNextButton, setShowNextButton] = useState(false);
    const [voucherValue, setVoucherValue] = useState('???');
    const [isCheckingVoucherValue, setIsCheckingVoucherValue] = useState(false);
    const [isVoucherValueFinal, setIsVoucherValueFinal] = useState(false);
    const [smsSentStatus, setSmsSentStatus] = useState(0);
    const [voucherDataError, setVoucherDataError] = useState(false);
    const [voucherData, setVoucherData] = useState({
        code: "...",
        name: "...",
        validTo: "..."
    });

    const updateSecurityCode = (event) => credentials.securityCode = event.target.value;

    const updateEmail = (event) => lead.email = event.target.value;

    const isDevelopment = () => process.env.NODE_ENV === 'development';

    const authenticate = async () => {
        await fetch('/api/authenticate', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({securityCode: credentials.securityCode})
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200 || isDevelopment()) {
                setShowInvalidCredentialsWarning(0);
                setDrawStage(2);
            } else if (resJson.status === "already_used") {
                setShowInvalidCredentialsWarning(2);
            } else {
                setShowInvalidCredentialsWarning(1);
            }
        }).catch((err) => {
            setShowInvalidCredentialsWarning(1);
        });
    }

    const getVoucherData = async () => {
        await fetch('/api/get-voucher-data', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({securityCode: credentials.securityCode})
        }).then(async (res) => {
            if (res.status === 200 || isDevelopment()) {
                const data = await res.json();
                setVoucherData(data.voucher);
            } else {
                setVoucherDataError(true);
            }
        }).catch((err) => {
            setVoucherDataError(true);
        });
    };

    const sendVoucherViaSms = async () => {
        await fetch('/api/send-sms', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({securityCode: credentials.securityCode})
        }).then((res) => {
            if (res.status === 200 || isDevelopment()) {
                setSmsSentStatus(1);
            } else {
                setSmsSentStatus(2);
            }
        }).catch((err) => {
            setSmsSentStatus(2);
        });
    };

    const offerInquiry = async () => {
        await fetch('/api/subscribe', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email: lead.email})
        }).then((res) => {
            if (res.status === 200 || isDevelopment()) {
                setShowLeadAdded(1);
            } else {
                setShowLeadAdded(2);
            }
        }).catch((err) => {
            setShowLeadAdded(2);
        });
    };

    const samplePrizes = [
        "atlas Polska niezwykła",
        "bilet weekendowy PKP",
        "powerbank",
        "kosmetyczka podróżna",
        "voucher Ryanair"
    ];

    return (
        <div className="App">
            <ThemeProvider theme={mysteryGiftTheme}>

                <img src={"/mysterygift.png"} alt="mysterygift"
                     style={{width: "75%", maxWidth: "300px", marginTop: "20px"}}/>

                <Card
                    color="primary"
                    size="md"
                    variant="outlined"
                    sx={{
                        backgroundColor: colors.bg,
                        color: colors.primary,
                        borderWidth: 3,
                        borderColor: colors.primaryLight,
                        padding: 4,
                        width: "80%",
                        margin: "auto",
                        maxWidth: "600px"
                    }}
                >
                    {drawStage === 0 && <div>
                        <h2>Witaj!</h2>
                        <p style={{textAlign: 'left'}}><br/>Lubisz sprawiać innym niespodzianki? <b>Świetnie się składa!</b><br/>
                            Wypróbuj naszą usługę Tajemniczy Prezent. Jak to działa?</p>
                        <ul id="instructions">
                            <li>Określ budżet i kategorię prezentu</li>
                            <li>Osoba Obdarowana otrzyma losowy, wysokiej jakości prezent z Katalogu Prezentów
                            </li>
                            <li>Szansa na wygranie Extra Prezentu o znacznie wyższej wartości
                            </li>
                        </ul>
                        <p style={{textAlign: 'left'}}>
                            Gwarantujemy satysfakcję Osoby Obdarowanej.
                        </p>
                        <p style={{textAlign: 'left'}}>
                            Usługa jest w fazie pilotażowej, zostaw swój adres e-mail, a przedstawimy Tobie
                            szczegółową ofertę.
                        </p>

                        <div>
                            <br/>
                            <TextField
                                required
                                id="field-email"
                                label="Twój adres e-mail"
                                defaultValue=""
                                color="primary"
                                size="large"
                                focused
                                style={{paddingRight: 20}}
                                onChange={updateEmail}
                            />
                        </div>
                        <div>
                            <br/><br/>
                            <Button variant="contained" size="large" onClick={offerInquiry}>Proszę o ofertę</Button>
                        </div>

                        {showLeadAdded === 1 &&
                            <div>
                                <br/>
                                <Alert variant="filled" severity="info">
                                    Dziękujemy! Skontaktujemy się w sprawie oferty Mysterygift.
                                </Alert>
                            </div>
                        }
                        {showLeadAdded === 2 &&
                            <div>
                                <br/>
                                <Alert variant="filled" severity="warning">
                                    Podano nieprawidłowy adres e-mail.
                                </Alert>
                            </div>
                        }

                    </div>}

                    {drawStage === 1 && <div>
                        <h2>Cześć Justyna!</h2>
                        <p style={{textAlign: 'left'}}><br/>Oto Twój tajemniczy prezent
                            od: <span
                                style={{fontWeight: 500}}>Filip</span><br/>
                            Filip przekazał nam, że lubisz: <span style={{fontWeight: 500}}>podróże</span></p>
                        <p style={{textAlign: 'left'}}>Zanim wylosujemy Twoją niespodziankę,
                            musimy upewnić się, że to na pewno Ty!<br/><br/></p>

                        <div>
                            <TextField
                                required
                                id="field-name"
                                label="Podaj kod zabezpieczający"
                                defaultValue=""
                                color="primary"
                                focused
                                style={{paddingRight: 20}}
                                onChange={updateSecurityCode}
                            />
                        </div>
                        <div>
                            <br/><br/>
                            <Button variant="contained" size="large" onClick={authenticate}>Wylosuj
                                prezent</Button>
                        </div>
                        {showInvalidCredentialsWarning === 1 &&
                            <div>
                                <br/>
                                <Alert variant="filled" severity="warning">
                                    Podano nieprawidłowy kod, voucher wygasł lub voucher nie jest jeszcze aktywny.
                                    Spróbuj jeszcze raz!
                                </Alert>
                            </div>
                        }
                        {showInvalidCredentialsWarning === 2 &&
                            <div>
                                <br/>
                                <Alert variant="filled" severity="info">
                                    Prezent został już wylosowany. Jeśli nie zapisałaś danych prezentu, skontaktuj się z
                                    osobą, która przekazała Tobie voucher.
                                </Alert>
                            </div>
                        }
                    </div>}

                    {drawStage === 2 && <div>
                        <h3 style={{minWidth: 320}}>Kliknij w koło,<br/>aby wylosować swój prezent</h3>

                        <WheelComponent
                            segments={segments}
                            segColors={segColors}
                            winningSegment='voucher Ryanair'
                            onChanged={(prize) => onChanged(prize)}
                            onFinished={(winner) => onFinished(winner)}
                            primaryColor='#ffd500'
                            contrastColor='#ffd500'
                            buttonText='Spin'
                            isOnlyOnce={true}
                            size={150}
                            upDuration={100}
                            downDuration={2000}
                            fontFamily='Arial'
                        />

                        {!showNextButton &&
                            <h2 dangerouslySetInnerHTML={{__html: `<span style="font-weight: normal;">Twój prezent:</span><br />${samplePrize}`}}/>}

                        {showNextButton && <div>
                            <h3>Wow! Wylosowałaś Extra Prezent - sprawdź jego wartość!</h3>
                            <Button variant="contained" size="large" onClick={onNext}>Dalej</Button></div>}

                    </div>}

                    {drawStage === 3 && <div>
                        <h3 style={{minWidth: 320}}>Brawo! Kliknij, <br/>aby sprawdzić wartość vouchera</h3>

                        <h2>{voucherValue} zł</h2>

                        {!isVoucherValueFinal &&
                            <Button variant="contained" size="large" disabled={isCheckingVoucherValue}
                                    onClick={checkVoucherValue}>Sprawdź</Button>

                        }
                        {isVoucherValueFinal && <div>
                            <h3>Masz ogromne szczęście! To obecnie najlepszy prezent, jaki można wylosować w
                                Mysterygift!</h3>
                            <Button variant="contained" size="large"
                                    onClick={getVoucherDetails}>Pokaż dane vouchera</Button>
                        </div>

                        }
                    </div>}

                    {drawStage === 4 && <div>
                        <h3 style={{minWidth: 320}}>Dane Twojego prezentu</h3>
                        <p style={{textAlign: 'left'}}>
                            Kod vouchera: <b>{voucherData.code}</b><br/>
                            Imię i nazwisko: <b>{voucherData.name}</b><br/>
                            Data ważności: <b>{voucherData.validTo}</b><br/>
                        </p>
                        <p style={{textAlign: 'left'}}>
                            Voucher możesz wykorzystać na stronie i w aplikacji mobilnej Ryanair.
                        </p>
                        <p style={{textAlign: 'left'}}>
                            Zapisz szczegóły swojego prezentu, np. wykonując zrzut ekranu.
                        </p>
                        {voucherDataError &&
                            <div>
                                <br/>
                                <Alert variant="filled" severity="warning">
                                    Nie można pobrać danych vouchera. Skontaktuj się z osobą, od której otrzymałaś
                                    voucher.
                                </Alert>
                            </div>
                        }
                        {smsSentStatus === 1 &&
                            <div>
                                <br/>
                                <Alert variant="filled" severity="info">
                                    Wysłaliśmy dane Twojego vouchera SMS-em na nr telefonu podany przez: Filip
                                </Alert>
                            </div>
                        }
                        {smsSentStatus === 2 &&
                            <div>
                                <br/>
                                <Alert variant="filled" severity="warning">
                                    Nie możemy wysłać SMS na Twój numer. Zapisz dane vouchera, zanim zamkniesz ten
                                    ekran!
                                </Alert>
                            </div>
                        }
                    </div>}

                    {drawStage === 99 && <div>
                        <h2 style={{minWidth: 320}}>Ups, mamy problem...</h2>

                        <p style={{fontSize: "1.2em", textAlign: "left"}}><br/>
                            Link do vouchera jest nieprawidłowy, lub próbujesz wykorzystać voucher poza datą jego
                            ważności.<br/><br/>
                            Aby uzyskać pomoc, skontaktuj się z osobą, która podarowała Tobie voucher.
                        </p>

                    </div>
                    }
                </Card>
            </ThemeProvider>
        </div>

    )
        ;
}

export default App;
